import { all, takeLatest, call, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import Request from "networking/Request";
import { TResponse } from "types";
import ToastCenter from "utils/ToastCenter";

import {
  uploadLeads,
  didUploadLeads,
  didFailToUploadLeads,
  addLead,
  didAddLead,
  didFailToAddLead,
  findLead,
  didFindLead,
  didFailToFindLead,
  deleteLead,
  didDeleteLead,
  didFailToDeleteLead,
  mapLead,
  didMapLead,
  didFailToMapLead,
  updateLead,
  didUpdateLead,
  didFailToUpdateLead,
  loadLeads,
  didLoadLeads,
  didFailToLoadLeads,
  deleteLeadExcel,
  didDeleteLeadExcel,
  didFailToDeleteLeadExcel,
} from "./slice";

const UPLOAD_ENDPOINT = "v1/admin/content/importLeadExcel";
const ADD_ENDPOINT = "v1/admin/lead/addLead";
const SEARCH_ENDPOINT = "v1/admin/lead/findByEmail";
const DELETE_LEAD_ENDPOINT = "v1/admin/lead/deleteLead";
const MAP_LEAD_ENDPOINT = "v1/admin/lead/mapSalesRm";
const UPDATE_LEAD_ENDPOINT = "v1/admin/lead/updateLead";
const LOAD_LEADS_ENDPOINT = "v1/admin/lead/findLeadListByPage";
const LEAD_DELETE_EXCEL = "v1/admin/content/deleteLeadExcel";

function* handleUpload(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };

  const response: TResponse = yield call(
    Request.uploadExcel,
    UPLOAD_ENDPOINT,
    params
  );
  if (response.error) {
    ToastCenter.error(response?.error || "");
    yield put(didFailToUploadLeads(response.error));
  } else {
    yield put(didUploadLeads());
  }
}

function* handleAdd(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };

  const response: TResponse = yield call(Request.POST, ADD_ENDPOINT, params);

  if (response.error) {
    ToastCenter.error(response?.error || "");
    yield put(didFailToAddLead(response.error));
  } else {
    yield put(didAddLead());
  }
}

function* handleFindLead(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };

  const response: TResponse = yield call(Request.POST, SEARCH_ENDPOINT, params);

  if (response.error) {
    yield put(didFailToFindLead(response.error));
  } else {
    yield put(didFindLead(response.data));
  }
}

function* handleDeleteLead(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };

  const response: TResponse = yield call(
    Request.POST,
    DELETE_LEAD_ENDPOINT,
    params
  );

  if (response.error) {
    ToastCenter.error(response?.error || "");
    yield put(didFailToDeleteLead(response.error));
  } else {
    yield put(didDeleteLead());
  }
}

function* handleDeleteLeadExcel(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };

  const response: TResponse = yield call(
    Request.uploadExcel,
    LEAD_DELETE_EXCEL,
    params
  );

  if (response.error) {
    ToastCenter.error(response?.error || "");
    yield put(didFailToDeleteLeadExcel(response.error));
  } else {
    yield put(didDeleteLeadExcel());
  }
}

function* handleMapLead(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };

  const response: TResponse = yield call(
    Request.POST,
    MAP_LEAD_ENDPOINT,
    params
  );

  if (response.error) {
    ToastCenter.error(response?.error || "");
    yield put(didFailToMapLead(response.error));
  } else {
    yield put(
      loadLeads({
        pageNumber: 0,
        size: 1000,
      })
    );
    ToastCenter.success("Successfully assign lead to saleRM");
    yield put(didMapLead());
  }
}

function* handleUpdateLead(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };

  const response: TResponse = yield call(
    Request.POST,
    UPDATE_LEAD_ENDPOINT,
    params
  );

  if (response.error) {
    ToastCenter.error(response?.error || "");
    yield put(didFailToUpdateLead(response.error));
  } else {
    yield put(didUpdateLead());
  }
}

function* handleLoadLeads(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };

  const response: TResponse = yield call(
    Request.POST,
    LOAD_LEADS_ENDPOINT,
    params
  );

  if (response.error) {
    // ToastCenter.error(response?.error || "");
    yield put(didFailToLoadLeads(response.error));
  } else {
    yield put(didLoadLeads(response.data.data.leadResponseList));
  }
}

function* watcherUploadLead() {
  yield takeLatest(uploadLeads.type, handleUpload);
}
function* watcherAddLead() {
  yield takeLatest(addLead.type, handleAdd);
}
function* watcherFindLead() {
  yield takeLatest(findLead.type, handleFindLead);
}
function* watcherDeleteLead() {
  yield takeLatest(deleteLead.type, handleDeleteLead);
}
function* watcherDeleteLeadExcel() {
  yield takeLatest(deleteLeadExcel.type, handleDeleteLeadExcel);
}

function* watcheMapLead() {
  yield takeLatest(mapLead.type, handleMapLead);
}

function* watcheUpdateLead() {
  yield takeLatest(updateLead.type, handleUpdateLead);
}

function* watcheLoadLeads() {
  yield takeLatest(loadLeads.type, handleLoadLeads);
}

export function* leadManagementSaga() {
  yield all([
    watcherDeleteLeadExcel(),
    watcherUploadLead(),
    watcherAddLead(),
    watcherFindLead(),
    watcherDeleteLead(),
    watcheMapLead(),
    watcheUpdateLead(),
    watcheLoadLeads(),
  ]);
}
