import { Button, Card, Grid } from "@mui/material";
import { Box } from "@mui/system";
import colors from "res/colors";
import { DisplayRmProps } from ".";
import Text from "components/Text";
const DisplayRm: React.FC<DisplayRmProps> = ({ rmList, onClick }) => {
  const handleClick = (email: any) => {
    onClick(email);
  };
  return (
    <Box sx={{ mt: "20px" }}>
      {rmList.map((rm) => {
        return (
          <Card
            elevation={0}
            variant="outlined"
            sx={{ my: "20px", py: "50px", px: "50px" }}
          >
            <Grid container>
              <Grid sx={{ mb: "20px" }}>
                <Text type="listMediumBold" gutterBottom>
                  {rm.name}
                </Text>
              </Grid>
              <Grid container direction="row" sx={{ mb: "20px" }}>
                <Grid xs={4}>
                  <Text type="listSmallBold">Email Id</Text>
                  <Text type="listSmall">{rm.email}</Text>
                </Grid>
                <Grid xs={4}>
                  <Text type="listSmallBold">Mobile</Text>
                  <Text type="listSmall">{rm.mobile}</Text>
                </Grid>
                <Grid xs={4}>
                  <Text type="listSmallBold">Location</Text>
                  <Text type="listSmall">{rm.location}</Text>
                </Grid>
              </Grid>
              <Grid sx={{ mb: "20px" }}>
                <Grid xs={12}>
                  <Text type="listSmallBold">Address</Text>
                  <Text type="listSmall">{rm.address}</Text>
                </Grid>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              sx={{ backgroundColor: colors.backGroundBlue }}
              onClick={() => {
                handleClick(rm.email);
              }}
            >
              Assign
            </Button>
          </Card>
        );
      })}
    </Box>
  );
};

export default DisplayRm;
