import axios from "axios";

class Request {
  // constructor() {}

  static POST = async (
    endpoint: string,
    params?: any,
    config?: any
  ): Promise<any> => {
    try {
      const response = await axios.post(endpoint, params, config);

      if (
        response.data &&
        response.data.resultInfo &&
        (response.data.resultInfo.resultStatus === "-1" ||
          response.data.resultInfo.resultStatus === "5000")
        // (response.data.resultInfo.resultCode === 'FAILURE' ||
        //   response.data.resultInfo.resultStatus === '0')
      ) {
        return {
          error: response.data.resultInfo.resultMsg,
          error_type: response.data.resultInfo.resultCodeId,
        };
      }
      return response;
    } catch (error: any) {
      return Request.getErrorMessage(error);
    }
  };
  static uploadExcel = async (endpoint = "", data: any) => {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    let form = new FormData();
    try {
      if (data.file) {
        form.append(
          "file",
          data.file,
          "file_" + new Date().getTime() + data.file.name
        );
      } else {
        form.append("file", "");
      }
      const uploadresponse = await axios.post(endpoint, form, config);

      if (
        uploadresponse?.data &&
        uploadresponse?.data?.resultInfo &&
        (uploadresponse?.data?.resultInfo?.resultStatus === "-1" ||
          uploadresponse?.data?.resultInfo?.resultStatus === "5000")
        // (uploadresponse.data.resultInfo.resultCode === 'FAILURE' ||
        //   uploadresponse.data.resultInfo.resultStatus === '0')
      ) {
        return {
          error: uploadresponse?.data?.resultInfo?.resultMsg,
          error_type: uploadresponse?.data?.resultInfo?.resultCodeId,
        };
      }
      return uploadresponse;
    } catch (error: any) {
      return Request.getErrorMessage(error);
    }
  };
  static uploadContent = async (endpoint = "", data: any) => {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    let form = new FormData();

    try {
      if (data.thumbnail_file) {
        form.append(
          "thumbnail_file",
          data.thumbnail_file,
          "thumbnail_file" + new Date().getTime() + data.thumbnail_file.name
        );
      } else {
        form.append("thumbnail_file", "");
      }
      if (data.detail_file) {
        form.append(
          "detail_file",
          data.detail_file,
          "detail_file" + new Date().getTime() + data.detail_file.name
        );
      } else {
        form.append("detail_file", "");
      }

      if (data.material_file) {
        form.append(
          "material_file",
          data.material_file,
          "material_file" + new Date().getTime() + data.material_file.name
        );
      } else {
        form.append("material_file", "");
      }

      if (data.imageRequest) {
        form.append("imageRequest", JSON.stringify(data.imageRequest));
      }

      const uploadresponse = await axios.post(endpoint, form, config);
      if (
        uploadresponse?.data &&
        uploadresponse?.data?.resultInfo &&
        (uploadresponse?.data?.resultInfo?.resultStatus === "-1" ||
          uploadresponse?.data?.resultInfo?.resultStatus === "5000")
        // (uploadresponse.data.resultInfo.resultCode === 'FAILURE' ||
        //   uploadresponse.data.resultInfo.resultStatus === '0')
      ) {
        return {
          error: uploadresponse?.data?.resultInfo?.resultMsg,
          error_type: uploadresponse?.data?.resultInfo?.resultCodeId,
        };
      }

      return uploadresponse;
    } catch (error: any) {
      return Request.getErrorMessage(error);
    }
  };
  static uploadBanner = async (endpoint = "", data: any) => {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    let form = new FormData();

    try {
      if (data?.banner) {
        form.append(
          "banner",
          data.banner,
          "banner" + new Date().getTime() + data.banner.name
        );
      } else if (data?.image) {
        form.append("image", data.image, data?.image?.name);
      } else {
        form.append("banner", "");
      }

      if (data?.imageRequest) {
        form.append("imageRequest", JSON.stringify(data.imageRequest));
      }
      if (data?.fundManagerName) {
        form.append("fundManagerName", data?.fundManagerName);
      }

      const uploadresponse = await axios.post(endpoint, form, config);
      if (
        uploadresponse?.data &&
        uploadresponse?.data?.resultInfo &&
        (uploadresponse?.data?.resultInfo?.resultStatus === "-1" ||
          uploadresponse?.data?.resultInfo?.resultStatus === "5000")
        // (uploadresponse.data.resultInfo.resultCode === 'FAILURE' ||
        //   uploadresponse.data.resultInfo.resultStatus === '0')
      ) {
        return {
          error: uploadresponse?.data?.resultInfo?.resultMsg,
          error_type: uploadresponse?.data?.resultInfo?.resultCodeId,
        };
      }

      return uploadresponse;
    } catch (error: any) {
      return Request.getErrorMessage(error);
    }
  };

  static createEvent = async (endpoint = "", data: any) => {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    let form = new FormData();

    try {
      if (data.event_image) {
        form.append(
          "event_image",
          data.event_image,
          "event_image" + new Date().getTime() + data.event_image.name
        );
      } else {
        form.append("event_image", "");
      }
      if (data.speaker_image) {
        form.append(
          "speaker_image",
          data.speaker_image,
          "speaker_image" + new Date().getTime() + data.speaker_image.name
        );
      } else {
        form.append("speaker_image", "");
      }
      if (data.banner_image) {
        form.append(
          "banner_image",
          data.banner_image,
          "banner_image" + new Date().getTime() + data.banner_image.name
        );
      } else {
        form.append("banner_image", "");
      }

      if (data.eventRequest) {
        form.append("eventRequest", JSON.stringify(data.eventRequest));
      }

      const uploadresponse = await axios.post(endpoint, form, config);

      return uploadresponse;
    } catch (error: any) {
      return Request.getErrorMessage(error);
    }
  };

  static createNotification = async (endpoint = "", data: any) => {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    let form = new FormData();
    try {
      if (data?.image) {
        form.append(
          "image",
          data?.image,
          "image" + new Date().getTime() + data?.image.name
        );
      } else {
        form.append("image", "");
      }

      if (data?.notificationRequestString) {
        form.append(
          "notificationRequestString",
          JSON.stringify(data?.notificationRequestString)
        );
      }

      const uploadresponse = await axios.post(endpoint, form, config);
      if (
        uploadresponse?.data &&
        uploadresponse?.data?.resultInfo &&
        (uploadresponse?.data?.resultInfo?.resultStatus === "-1" ||
          uploadresponse?.data?.resultInfo?.resultStatus === "5000")
        // (uploadresponse.data.resultInfo.resultCode === 'FAILURE' ||
        //   uploadresponse.data.resultInfo.resultStatus === '0')
      ) {
        return {
          error: uploadresponse?.data?.resultInfo?.resultMsg,
          error_type: uploadresponse?.data?.resultInfo?.resultCodeId,
        };
      }
      return uploadresponse;
    } catch (error: any) {
      return Request.getErrorMessage(error);
    }
  };

  static GET = async (endpoint: string, params?: any) => {
    try {
      const response = await axios.get(endpoint, { params: params });
      if (
        response.data &&
        response.data.resultInfo &&
        (response.data.resultInfo.resultStatus === "-1" ||
          response.data.resultInfo.resultStatus === "5000")
        // (response.data.resultInfo.resultCode === 'FAILURE' ||
        //   response.data.resultInfo.resultStatus === '0')
      ) {
        return {
          error: response.data.resultInfo.resultMsg,
          error_type: response.data.resultInfo.resultCodeId,
        };
      }
      return response;
    } catch (error: any) {
      return Request.getErrorMessage(error);
    }
  };
  static PUT = async (
    endpoint: string,
    params: any,
    config?: any
  ): Promise<any> => {
    try {
      const response = await axios.put(endpoint, params, config);
      return response;
    } catch (error: any) {
      return Request.getErrorMessage(error);
    }
  };
  static PATCH = async (
    endpoint: string,
    params: any,
    config?: any
  ): Promise<any> => {
    try {
      const response = await axios.patch(endpoint, params, config);
      return response;
    } catch (error: any) {
      return Request.getErrorMessage(error);
    }
  };

  static DELETE = async (endpoint: string, params: any) => {
    try {
      const response = await axios.delete(endpoint, { data: params });
      return response;
    } catch (error: any) {
      return Request.getErrorMessage(error);
    }
  };

  static getErrorMessage = (error: {
    response?: { data: { message?: string } };
  }) => {
    if (error.response) {
      return {
        error:
          error.response.data.message ||
          error.response.data ||
          "Something went wrong",
        error_type: "ERROR_TYPE_SERVER",
      };
    }

    return { error: "Network Error", error_type: "ERROR_TYPE_NETWORK" };
  };
}

export default Request;
