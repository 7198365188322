import { useState } from "react";
import { Box, FormControl, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { TSelectProps } from "./types";
import { SelectOption } from "components/FormSelect/types";
import Text from "components/Text";
import colors from "res/colors";
const FormSelect: React.FC<TSelectProps> = ({
  label,
  options,
  value = "",
  placeHolder = "",
  onOptionSelect,
  errorMessage = "",
  required = false,
}) => {
  // const [currentValue, setCurrentValue] = useState(value);
  const _selectOptions = options.map((item: SelectOption) => (
    <MenuItem value={item.value} key={item.value}>
      {item.title}
    </MenuItem>
    // <option value={item.value} key={item.value} disabled={item.disabled}>

    // </option>
  ));
  if (placeHolder) {
    _selectOptions.unshift(
      <MenuItem value={""} key={"empty"}>
        {placeHolder}
      </MenuItem>
    );
  }

  const handleChange = (event: { target: { value: any } }) => {
    const option = options.find((opt) => opt.value === event.target.value);
    if (option && onOptionSelect) {
      onOptionSelect(option);
    }
    // setCurrentValue(option?.value);
  };
  const showError = Boolean(errorMessage);
  return (
    <FormControl fullWidth>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Text type="extraSmall" gutterBottom component="div">
          {label}
        </Text>
        {required ? (
          <Text
            type="mediumBold"
            sx={{
              fontWeight: 600,
              fontSize: "16px",
              letterSpacing: "0em",
              lineHeight: "20px",
              fontFamily: "Graphik",
              color: colors.radicalRed,
            }}
          >
            *
          </Text>
        ) : null}
      </Box>
      <Select
        onChange={handleChange}
        error={showError}
        displayEmpty
        value={value}
      >
        {_selectOptions}
      </Select>
      {errorMessage ? (
        <Text type="mediumBold" color="red">
          {errorMessage}
        </Text>
      ) : null}
    </FormControl>
  );
};
export default FormSelect;
