import {
  Box,
  Button,
  Card,
  Grid,
  InputAdornment,
  IconButton,
  Divider,
} from "@mui/material";
import Input from "components/Input";
import Text from "components/Text";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import {
  loadLeads,
  resetDeleteLeadStates,
  resetUpdateLeadStates,
} from "../../store/slice";
import { useEffect, useState } from "react";
import { ReactComponent as SearchIcon } from "res/images/searchIcon.svg";
import AssignedLeadList from "./AssignedLeadList";
import ToastCenter from "utils/ToastCenter";
import { getCount } from "pages/SalesRMManagement/store/slice";
import CloseIcon from "@mui/icons-material/Close";
import SearchIconMUI from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import colors from "res/colors";

// const AssigneLeadData = [
//   {
//     id: 1,
//     name: "Vinayak Champak Gadepalli",
//     assignedOn: "9 Nov 2021",
//     assignedTo: "RM - Ankit Shaha",
//     email: "vinayak. cg@gmail.com",
//     mobile: 9876543210,
//     location: "Mumbai, Maharashtra",
//     address:
//       "501, Oberoi Springs, New link road, Andheri (West),  Mumbai 433093 Maharastra ",
//     status: "Open",
//   },
//   {
//     id: 2,
//     name: "Vinayak Champak Gadepalli",
//     assignedOn: "9 Nov 2021",
//     assignedTo: "RM - Ankit Shaha",
//     email: "vinayak. cg@gmail.com",
//     mobile: 9876543210,
//     location: "Mumbai, Maharashtra",
//     address:
//       "501, Oberoi Springs, New link road, Andheri (West),  Mumbai 433093 Maharastra ",
//     status: "No Response",
//   },
//   {
//     id: 3,
//     name: "Vinayak Champak Gadepalli",
//     assignedOn: "9 Nov 2021",
//     assignedTo: "RM - Ankit Shaha",
//     email: "vinayak. cg@gmail.com",
//     mobile: 9876543210,
//     location: "Mumbai, Maharashtra",
//     address:
//       "501, Oberoi Springs, New link road, Andheri (West),  Mumbai 433093 Maharastra ",
//     status: "Expired",
//   },
// ];

const AssignedLead = () => {
  const dispatch = useAppDispatch();
  const {
    // isLoadingLeads,
    leads,
    // loadLeadsError,
    didUpdateLead,
    didDeleteLead,
  } = useAppSelector((state) => state.leadManagement);
  const { countData } = useAppSelector((state) => state.salesRM);
  const { baseURL } = useAppSelector((state) => state.auth);
  useEffect(() => {
    if (didUpdateLead || didDeleteLead) {
      if (didUpdateLead) {
        dispatch(resetUpdateLeadStates());
        ToastCenter.success("Successfully updated Lead");
      }
      if (didDeleteLead === true) {
        dispatch(resetDeleteLeadStates());
        ToastCenter.success("Successfully delete Lead");
      }
      dispatch(
        loadLeads({
          pageNumber: 0,
          size: 1000,
        })
      );
      dispatch(getCount());
    }
  }, [didUpdateLead, didDeleteLead]);

  const [searchResult, setSearchResult] = useState(leads);
  const [searchValue, setSearchValue] = useState("");
  const [searchError, setSearchError] = useState("");

  useEffect(() => {
    setSearchResult(leads);
  }, [leads]);

  const handleDownloadLeadSheetStatus = () => {
    // dispatch(downloadSalesRMSheet());
    const url =
      baseURL + "v1/admin/leadExcel/download?isLeadStatusRequired=true";
    let a = document.createElement("a");
    a.setAttribute("href", url);
    a.click();
    ToastCenter.success(
      "File downloading in progress. Please wait for some time."
    );
  };
  const handleDownloadLeadSheetFormat = () => {
    // dispatch(downloadSalesRMSheet());
    const url =
      baseURL + "v1/admin/leadExcel/download?isLeadStatusRequired=false";
    let a = document.createElement("a");
    a.setAttribute("href", url);
    a.click();
    ToastCenter.success(
      "File downloading in progress. Please wait for some time."
    );
  };
  // const handleSearch = (text: string) => {
  //   if (!leads && leads.length < 1) return;

  //   const filteredValues = leads?.filter((item: any) => {
  //     return (
  //       item?.name?.toUpperCase().includes(text.toUpperCase()) ||
  //       item?.email?.toUpperCase().startsWith(text.toUpperCase()) ||
  //       item?.mobileNum?.toUpperCase().startsWith(text.toUpperCase())
  //     );
  //   });
  //   setSearchResult(filteredValues);
  // };

  useEffect(() => {
    if (!searchValue) {
      setSearchError("");
      setSearchResult(leads);
    }
  }, [searchValue]);

  const handleSearchPress = () => {
    setSearchError("");
    if (leads.length < 1) return;
    if (searchValue) {
      const filteredValues = leads?.filter((item: any) => {
        return (
          item?.name?.toUpperCase().includes(searchValue.toUpperCase()) ||
          item?.email?.toUpperCase().startsWith(searchValue.toUpperCase()) ||
          item?.mobileNum?.toUpperCase().startsWith(searchValue.toUpperCase())
        );
      });
      if (filteredValues?.length < 1) {
        setSearchError("No data found.");
      } else {
        setSearchError("");
      }
      setSearchResult(filteredValues);
    }
  };

  return (
    <Card sx={{ my: "24px", px: "32px", py: "32px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid
          container
          direction="row"
          spacing={2}
          sx={{
            pl: "20px",
            alignItems: "center",
            mb: "20px",
            width: "65%",
          }}
        >
          <Grid
            xs={4}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Text type="extraLargeBold">Assigned Lead</Text>
          </Grid>
          <Grid xs={3}>
            <Box>
              <Text type={"mediumBold"}>
                {`Leads Count: ${
                  countData?.leadsCount ? countData?.leadsCount : 0
                }`}
              </Text>
            </Box>
          </Grid>

          {/* <Grid xs={5}>
          <Input
            variant="standard"
            sx={{
              [`& .MuiFilledInput-root`]: {
                backgroundColor: "#F8F8F8",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              placeholder: "Search lead by name/email/mobile no.",
            }}
            onChange={(e: any) => {
              handleSearch(e?.target?.value);
            }}
          />
        </Grid> */}
        </Grid>
        <Button
          variant="contained"
          sx={{
            py: "5px",
            px: "10px",
            ml: "10px",
            mb: "24px",
            backgroundColor: colors.backGroundBlue,
            textTransform: "none",
            fontSize: "13px",
          }}
          onClick={handleDownloadLeadSheetFormat}
        >
          {/* <DownloadIcon sx={{ fontSize: "18px", pr: "2px" }} /> */}
          Export Upload Format
        </Button>
        <Button
          variant="contained"
          sx={{
            py: "5px",
            px: "10px",
            ml: "10px",
            mb: "24px",
            backgroundColor: colors.backGroundBlue,
            textTransform: "none",
            fontSize: "13px",
          }}
          onClick={handleDownloadLeadSheetStatus}
        >
          {/* <DownloadIcon sx={{ fontSize: "18px", pr: "2px" }} />  */}
          Export Lead Status
        </Button>
      </Box>
      <Box sx={{ mb: "2px" }}>
        <Divider variant="fullWidth" />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Input
          name="email"
          sx={{
            [`& .MuiFilledInput-root`]: {
              backgroundColor: "#F8F8F8",
            },
          }}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {searchValue ? (
                  <IconButton
                    color="default"
                    size="small"
                    sx={{}}
                    onClick={() => {
                      setSearchValue("");
                      setSearchError("");
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                ) : null}
              </InputAdornment>
            ),
            placeholder: "Search lead by name/email/mobile no.",
          }}
          onChange={(e: any) => {
            setSearchValue(e?.target?.value);
          }}
          value={searchValue}
        />
        <Button
          variant="contained"
          sx={{
            py: "14px",
            px: "30px",
            ml: "10px",
            backgroundColor: colors.backGroundBlue,
            textTransform: "none",
          }}
          onClick={handleSearchPress}
        >
          <SearchIconMUI /> Search
        </Button>
      </Box>
      <Box sx={{ mt: "2px", mb: "50px" }}>
        <Divider variant="fullWidth" />
      </Box>
      <AssignedLeadList leads={searchResult} />
      {searchError ? (
        <Box sx={{ mt: "24px", color: colors?.red, ml: "10px", mb: "10px" }}>
          {searchError}
        </Box>
      ) : null}
    </Card>
  );
};

export default AssignedLead;
