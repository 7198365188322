import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import Input from "components/Input";
import ImageDropzone from "components/ImageDropZone";
import Text from "components/Text";
import { ChangeEvent, useState, useRef, useEffect } from "react";
import colors from "res/colors";
import { ReactComponent as ThumbnailIcon } from "res/images/thumbnailIcon.svg";
import Warning from "@mui/icons-material/Warning";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";

import {
  uploadLeads,
  resetUploadLeadStates,
  loadLeads,
  deleteLeadExcel,
  resetDeleteLeadExcelStates,
} from "../../store/slice";
import ToastCenter from "utils/ToastCenter";
import { getCount } from "pages/SalesRMManagement/store/slice";

const UploadLead = () => {
  const dispatch = useAppDispatch();
  const { uploadLeadsError, didUploadLeads, didDeleteLeadExcel } =
    useAppSelector((state) => state.leadManagement);

  const imageDropzoneRef = useRef<any>(null);
  const [uploadDialog, setUploadDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const handleUploadClose = () => {
    setUploadDialog(false);
  };
  const handleDeleteClose = () => {
    setDeleteDialog(false);
  };

  const handleUploadConfirm = () => {
    setUploadDialog(false);
    const data = imageDropzoneRef.current.getImage();
    dispatch(uploadLeads({ file: data }));
  };
  const handleDeleteConfirm = () => {
    setDeleteDialog(false);
    const data = imageDropzoneRef.current.getImage();
    dispatch(deleteLeadExcel({ file: data }));
  };

  useEffect(() => {
    if (didUploadLeads) {
      ToastCenter.success("Successfully added Leads.");
      imageDropzoneRef.current.clearImage();
      dispatch(resetUploadLeadStates());
      dispatch(getCount());
      dispatch(
        loadLeads({
          pageNumber: 0,
          size: 1000,
        })
      );
    }
  }, [didUploadLeads]);

  useEffect(() => {
    if (didDeleteLeadExcel) {
      ToastCenter.success("Successfully deleted Leads.");
      imageDropzoneRef.current.clearImage();
      dispatch(resetDeleteLeadExcelStates());
      dispatch(getCount());
      dispatch(
        loadLeads({
          pageNumber: 0,
          size: 1000,
        })
      );
    }
  }, [didDeleteLeadExcel]);

  const handleUpload = () => {
    const data = imageDropzoneRef.current.getImage();
    if (!data) {
      return;
    }
    // dispatch(uploadLeads({ file: data }));
    setUploadDialog(true);
  };

  const handleDeleteExcel = () => {
    const data = imageDropzoneRef.current.getImage();
    if (!data) {
      return;
    }
    setDeleteDialog(true);
  };

  const handleImageDrop = (imageData: { url: string; name: string }) => {
    // const { name } = imageDropzoneRef.current.getImage();
    // setValue('image', { imageData: imageData, name: name });
  };
  return (
    <div>
      <Box
        sx={{
          // mt: "20px",
          mb: "20px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Warning sx={{ color: colors.red, fontSize: "18px", mr: "5px" }} />
        <Text type="medium" sx={{ color: colors.red }}>
          Warning : Please do not upload/edit/delete Leads during business hours
          (9 AM - 7 PM).
        </Text>
      </Box>
      <ImageDropzone
        onDropped={handleImageDrop}
        ref={imageDropzoneRef}
        heading="Upload Excel"
        icon={<ThumbnailIcon height={"44px"} width={"56px"} />}
        type="Excel"
      />
      {/* <Box sx={{ mt: "40px", mb: "20px" }}>
        <Divider variant="fullWidth" />
      </Box> */}
      {/* <Box
        sx={{
          pb: "16px",
        }}
      > */}
      {/* <Text type="mediumBold">And add text here</Text>
      </Box>
      <Input
        variant="filled"
        label="Add Title"
        onChange={handleTitle}
        InputProps={{
          disableUnderline: true,
        }}
      /> */}
      {/* <Box sx={{ my: "40px" }}>
        <Divider variant="fullWidth" />
      </Box> */}
      {/* <Box
        sx={{
          // mt: "20px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Warning sx={{ color: colors.red, fontSize: "18px", mr: "5px" }} />
        <Text type="medium" sx={{ color: colors.red }}>
          Warning : Please do not upload Leads during business hours (9 AM - 5
          PM).
        </Text>
      </Box> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
        }}
      >
        {/* <Button
          variant="contained"
          sx={{
            fontSize: "18px",
            lineHeight: "24px",
            py: "16px",
            px: "48px",
            fontWeight: 500,
            backgroundColor: colors.backGroundBlue,
          }}
          onClick={handleUpload}
        >
          Submit
        </Button> */}
        <Dialog open={uploadDialog}>
          <DialogTitle>Confirm upload?</DialogTitle>
          <DialogContent>This action will add/update the data.</DialogContent>
          <DialogActions>
            <Button onClick={handleUploadClose}>Cancel</Button>
            <Button onClick={() => handleUploadConfirm()} autoFocus>
              Upload
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

      <Box
        sx={{
          mt: "10px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          sx={{
            py: "12px",
            px: "20px",
            mr: "20px",
            mt: "20px",
            backgroundColor: colors.backGroundBlue,
            textTransform: "none",
          }}
          onClick={handleUpload}
        >
          Add/Update Data
        </Button>
        <Button
          variant="contained"
          sx={{
            py: "12px",
            px: "20px",
            mr: "20px",
            mt: "20px",
            backgroundColor: colors.backGroundBlue,
            textTransform: "none",
          }}
          onClick={handleDeleteExcel}
        >
          Delete Data
        </Button>
        <Dialog open={deleteDialog}>
          <DialogTitle>Confirm Delete?</DialogTitle>
          <DialogContent>
            This action will permanently delete the data.
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteClose}>Cancel</Button>
            <Button onClick={() => handleDeleteConfirm()} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </div>
  );
};

export default UploadLead;
