import { Checkbox } from "@mui/material";
import { Box } from "@mui/system";
import Text from "components/Text";
import React from "react";
import { CheckBoxProps } from "./types";
import CropSquareIcon from "@mui/icons-material/CropSquare";

const CheckBox: React.FC<CheckBoxProps> = ({
  type,
  message1,
  message2,
  onClick,
  checked = false,
}) => {
  const setMessage = () => {
    if (type === "Trending") {
      message1 = "Trending";
      message2 = "This article will be trending on the dashboard";
    } else if (type === "Featured") {
      message1 = "Featured Videos";
      message2 = "This video will be featured on the dashboard";
    } else if (type === "TopBanner") {
      message1 = "Top Banner";
      message2 = "This event will be showcase on the dashboard";
    } else if (type === "Recomended") {
      message1 = "Recomended";
      message2 = "This article will be recomended on the dashboard";
    } else if (type === "Youtube") {
      message1 = "Is Youtube";
      message2 = "Check this if content is from youtube";
    } else if (type === "ShowNotification") {
      message1 = "Send Push Notification";
      message2 = "Check this if you want to send push notification";
    }
  };

  // const [isChecked, setIsChecked] = React.useState(checked);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setIsChecked(event.target.checked);
    onClick(event.target.checked);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <Checkbox
        icon={
          <CropSquareIcon sx={{ fontSize: "40px", borderRadius: "25px" }} />
        }
        sx={{ height: "32px", width: "32px" }}
        checked={checked}
        onChange={handleChange}
      />
      <Box sx={{ display: "flex", flexDirection: "column", pl: "16px" }}>
        {setMessage()}
        <Box sx={{ pb: "8px" }}>
          <Text type="mediumBold">{message1}</Text>
        </Box>

        <Box sx={{ pb: "16px" }}>
          <Text type="smallTransparent">{message2}</Text>
        </Box>
      </Box>
    </Box>
  );
};

export default CheckBox;
