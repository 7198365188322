/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Button, Grid } from "@mui/material";
import CheckBox from "components/CheckBox";
import FormInput from "components/FormInput";
import RichTextInput from "components/RichTextInput";
import ToggleOption from "components/ToggleOption";
import ImageDropzone from "components/ImageDropZone";
import FormSelect from "components/FormSelect/FormSelect";
import FileInput from "components/FileInput";
import { ChangeEvent, useState, useRef } from "react";
import colors from "res/colors";
import { ReactComponent as ThumbnailIcon } from "res/images/thumbnailIcon.svg";
import { ReactComponent as UploadDetailIcon } from "res/images/uploadDetailIcon.svg";
import Text from "components/Text";
import ActivityOverlay from "components/ActivityOverlay";
import { useEffect } from "react";

import { TContentForm } from "../types";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { contentValidationSchema } from "./validationSchema";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { resetUpdateContent, updateContent } from "../store/slice";
import ToastCenter from "utils/ToastCenter";
import MultiSelect from "components/MultiSelect";
import moment from "moment";
import DateInput from "components/DateInput";
import Editor from "components/Editor";

export interface EditContentrProps {
  content: any;
  onCancel: () => void;
}

const EditProductVideo: React.FC<EditContentrProps> = ({
  content,
  onCancel,
}) => {
  const dispatch = useAppDispatch();
  const { isUpdateContent, updateContentError, didUpdateContent } =
    useAppSelector((state) => state.contentManagement);
  const { schemeNames } = useAppSelector((state) => state.schemes);

  const [isTrending, setIsTrending] = useState(false);
  // const [showPreview, setShowPreview] = useState(true);
  const [isRecomended, setIsRecomended] = useState(false);
  const [isFeatured, setIsFeatured] = useState(false);
  const [isYoutube, setIsYoutube] = useState(false);
  const [thumbnailImageError, setThumbnailImageError] = useState("");
  const [videoError, setVideoError] = useState("");
  const [videoType, setVideoType] = useState("url link");
  const imageDropzoneVideoRef = useRef<any>(null);
  const videoUrlInputRef = useRef<any>(null);
  const imageDropzoneThumbnailRef = useRef<any>(null);
  const videoTypeOptions = [{ label: "url link" }, { label: "video file" }];
  const [selectedSchemes, setSelectedSchemes] = useState([]);
  const [isShowNotification, setIsShowNotification] = useState(false);

  const formMethods = useForm<TContentForm>({
    mode: "onSubmit",
    reValidateMode: "onChange",

    resolver: yupResolver(contentValidationSchema),
  });

  useEffect(() => {
    formMethods.setValue("description", content?.description);
    formMethods.setValue("creator", content?.creatorName);
    formMethods.setValue("designation", content?.creatorDesignation);
    formMethods.setValue("thumbnail_file", "");
    formMethods.setValue("material_file", "");
    formMethods.setValue("title", content?.title);
    formMethods.setValue("category", content?.category);
    formMethods.setValue("description", content?.description);
    formMethods.setValue("creator", content?.creatorName);
    formMethods.setValue("designation", content?.creatorDesignation);
    formMethods.setValue("videoUrl", content?.assetUrl);
    setIsTrending(content?.trending);
    setIsRecomended(content?.recommended);
    setIsFeatured(content?.featuredVideo);
    setIsYoutube(content?.youtubeVideo);
    formMethods.setValue("schemes", content?.schemes);
    const allSchems = content?.schemes ? content?.schemes.split("$") : [];
    setSelectedSchemes(allSchems);
    setVideoType("url link");
    if (didUpdateContent) {
      ToastCenter.success("Successfully updated Product Video");

      formMethods.setValue("thumbnail_file", null);
      formMethods.setValue("material_file", null);
      setIsTrending(false);
      setIsRecomended(false);
      setIsFeatured(false);
      setIsYoutube(false);
      setIsShowNotification(false);
      setVideoType("url link");
      imageDropzoneThumbnailRef.current.clearImage();
      if (imageDropzoneVideoRef.current)
        imageDropzoneVideoRef.current.clearImage();
      if (videoUrlInputRef.current) videoUrlInputRef.current.clearText();
      formMethods.setValue("title", "");
      formMethods.setValue("category", "");
      formMethods.setValue("description", "");
      formMethods.setValue("creator", "");
      formMethods.setValue("designation", "");
      formMethods.setValue("schemes", "");
      formMethods.setValue("contentUploadDate", "");
      formMethods.setValue("notificationTitle", "");
      formMethods.setValue("notificationShortDescription", "");
      formMethods.setValue("notificationDescription", "");
      // dispatch(resetStates());
      onCancel();
    }

    return () => {
      dispatch(resetUpdateContent());
    };
  }, [didUpdateContent]);

  const onSubmit = (data: TContentForm) => {
    let showError = false;

    // if (!data.thumbnail_file) {
    //   showError = true;
    //   setThumbnailImageError("Please set thumbnail image");
    // }

    // if (videoType === "video file" && !data.material_file) {
    //   showError = true;
    //   setVideoError("Please set video content");
    // }

    // if (videoType === "url link" && !data.videoUrl) {
    //   showError = true;
    //   setVideoError("Please set video url");
    // }

    if (showError) {
      return;
    }

    const params: any = {
      thumbnail_file: data.thumbnail_file,

      imageRequest: {
        id: content.id,
        trending: isTrending,
        recommended: isRecomended,
        youtubeVideo: isYoutube,
        title: data.title,
        contentCategories: "PRODUCT_VIDEO",
        category: data.category,
        description: data.description || "",
        videoCreator: data.creator,
        CreatorDesignation: data.designation,
        featuredVideo: isFeatured,
        schemes: data.schemes || "",
        contentUploadDate: data.contentUploadDate || null,
        enableNotification: isShowNotification,
        userType: ["MFD", "MFDRM", "SALESRM", "RIA"],
        notificationTitle: data?.notificationTitle ?? "",
        notificationShortDescription: data?.notificationShortDescription ?? "",
        notificationDescription: data?.notificationDescription ?? "",
      },
    };
    if (videoType === "url link") {
      params.imageRequest.videoUrl = data.videoUrl;
    } else {
      params.material_file = data.material_file;
    }

    dispatch(updateContent(params));
    // signInCallBack(data.email);
  };

  const handleNotificationCheckBoxChange = (checked: boolean) => {
    setIsShowNotification(checked);
    formMethods.setValue("isShowNotification", checked, {
      shouldValidate: true,
    });
  };

  const handleOnDescriptionChange = (data: any) => {
    formMethods.setValue("description", data);
  };
  const handleVideoType = (videoTypeSelected: string) => {
    setVideoType(videoTypeSelected);
  };
  const handleVideoDrop = (imageData: { url: string; name: string }) => {
    formMethods.setValue("material_file", imageData);
  };
  const handleThumbnailDrop = (imageData: { url: string; name: string }) => {
    // setShowPreview(false);
    formMethods.setValue("thumbnail_file", imageData);
  };
  const handleTrendingCheckBoxChange = (checked: boolean) => {
    setIsTrending(checked);
  };
  const handleIsYoutubeCheckBoxChange = (checked: boolean) => {
    setIsYoutube(checked);
  };
  const handleRecomendedCheckBoxChange = (checked: boolean) => {
    setIsRecomended(checked);
  };
  const handleFeaturedCheckBoxChange = (checked: boolean) => {
    setIsFeatured(checked);
  };

  const handleVideoURLChane = (url: string) => {
    formMethods.setValue("videoUrl", url);
  };

  const onSelectSchemes = (item: Array<string>) => {
    formMethods.setValue("schemes", item.join("$"));
  };

  const renderVideoSelectType = () => {
    if (videoType === "url link") {
      return (
        <Box sx={{ mt: "24px" }}>
          <FileInput
            icon={<UploadDetailIcon height={"44px"} width={"56px"} />}
            type="Url"
            onChange={handleVideoURLChane}
            error={videoError}
            ref={videoUrlInputRef}
          />
        </Box>
      );
    }
    return (
      <Box sx={{ mt: "24px" }}>
        <ImageDropzone
          onDropped={handleVideoDrop}
          ref={imageDropzoneVideoRef}
          heading=""
          icon={<UploadDetailIcon height={"44px"} width={"56px"} />}
          type="Video"
          error={videoError}
        />
      </Box>
    );
  };

  return (
    <Box sx={{ mt: "40px" }}>
      <ImageDropzone
        onDropped={handleThumbnailDrop}
        ref={imageDropzoneThumbnailRef}
        heading="Upload Thumbnail Image"
        icon={<ThumbnailIcon height={"44px"} width={"56px"} />}
        type="Image"
        error={thumbnailImageError}
        resolution={"780w X 440h"}
      />

      {/* {content.thumbnailImage && showPreview ? (
        <div>
          <Box sx={{ pb: "16px", mt: "10px" }}></Box>
          <Text type="mediumBold">Preview</Text>
          <img
            src={content.thumbnailImage}
            style={{ width: "150px", height: "150px" }}
            alt={"default"}
          />
        </div>
      ) : null} */}

      <Box sx={{ pb: "16px", pt: "24px" }}>
        <Text type="mediumBold">Upload Video here</Text>
      </Box>

      <ToggleOption
        options={videoTypeOptions}
        onClick={handleVideoType}
        selected={videoType}
      />
      {renderVideoSelectType()}
      {videoType === "url link" ? (
        <Grid container spacing={2} mt={"15px"}>
          <Grid item xs={6}>
            <CheckBox
              type="Youtube"
              checked={isYoutube}
              onClick={handleIsYoutubeCheckBoxChange}
            />
          </Grid>
        </Grid>
      ) : null}
      <Box sx={{ my: "40px" }}>
        <Divider variant="fullWidth" />
      </Box>
      <Box sx={{ pb: "16px" }}>
        <Text type="mediumBold">And add text here</Text>
      </Box>
      <FormProvider {...formMethods}>
        <FormInput
          variant="filled"
          name="title"
          InputProps={{
            disableUnderline: true,
          }}
          label="Add Title"
        />
        <Box sx={{ height: "20px" }} />
        <DateInput
          label="Content Date"
          // error={formMethods.formState.errors.eventDate?.message}
          onChange={(newValue: Date | null) => {
            if (newValue) {
              formMethods.setValue(
                "contentUploadDate",
                moment(newValue).format("YYYY-MM-DD")
              );
            }
          }}
          preValue={
            content?.contentUploadDate
              ? moment(content?.contentUploadDate).format("YYYY-MM-DD")
              : ""
          }
        />
        <Box sx={{ height: "20px" }} />
        <FormInput
          variant="filled"
          name="creator"
          InputProps={{
            disableUnderline: true,
          }}
          label="Add Creator Name"
        />
        <Box sx={{ height: "20px" }} />
        <FormInput
          variant="filled"
          name="designation"
          InputProps={{
            disableUnderline: true,
          }}
          label="Add Creator Designation"
        />
        <Box sx={{ height: "20px" }} />
        <FormSelect
          label="Select Type"
          labelId="type"
          id="type"
          options={[
            {
              title: "Equity",
              value: "Equity",
            },
            {
              title: "Fixed Income",
              value: "Fixed Income",
            },
            {
              title: "Fund Of Funds",
              value: "Fund Of Funds",
            },
            {
              title: "Exchange Traded Fund",
              value: "Exchange Traded Fund",
            },
            {
              title: "Hybrid",
              value: "Hybrid",
            },
          ]}
          name="category"
        />
        <Box sx={{ height: "20px" }} />
        <MultiSelect
          label="Select Related Schemes"
          labelId="schemes"
          id="schemes"
          options={schemeNames}
          onOptionSelect={onSelectSchemes}
          defaultValue={selectedSchemes}
        />
        <Box sx={{ height: "20px" }} />
        {/* <RichTextInput
        onChange={handleOnDescriptionChange}
        error={formMethods.formState.errors["description"]?.message}
      /> */}
        <FormInput
          variant="filled"
          name="description"
          InputProps={{
            disableUnderline: true,
          }}
          label="Add Description"
          multiline
          rows={4}
        />
      </FormProvider>
      <Box sx={{ my: "40px" }}>
        <Divider variant="fullWidth" />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CheckBox
            type="Trending"
            checked={isTrending}
            onClick={handleTrendingCheckBoxChange}
          />
        </Grid>
        <Grid item xs={6}>
          <CheckBox
            type="Recomended"
            checked={isRecomended}
            onClick={handleRecomendedCheckBoxChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={"15px"}>
        <Grid item xs={6}>
          <CheckBox
            type="Featured"
            checked={isFeatured}
            onClick={handleFeaturedCheckBoxChange}
          />
        </Grid>
        <Grid item xs={6}>
          <CheckBox
            type="ShowNotification"
            checked={isShowNotification}
            onClick={handleNotificationCheckBoxChange}
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: "40px", mb: "21px" }}>
        <Divider variant="fullWidth" />
      </Box>
      {isShowNotification ? (
        <>
          <Text type="mediumBold">Notification Details</Text>
          <FormProvider {...formMethods}>
            <FormInput
              variant="filled"
              name="notificationTitle"
              InputProps={{
                disableUnderline: true,
              }}
              label="Add Title (max 1000 characters) *"
              sx={{ mt: "20px " }}
              inputProps={{ maxLength: 1000 }}
            />

            <FormInput
              variant="filled"
              name="notificationShortDescription"
              label="Short descriptions (Max. 1000 characters) *"
              sx={{ my: "30px " }}
              inputProps={{ maxLength: 1000 }}
            />
            <Editor
              placeholder="Descriptions (Max. 1000 characters)"
              onChange={(text) => {
                formMethods.setValue("notificationDescription", text, {
                  shouldValidate: true,
                });
              }}
              reset={didUpdateContent}
            />
            {formMethods.formState.errors?.notificationDescription?.message && (
              <Text type="error">
                {formMethods.formState.errors?.notificationDescription?.message}
              </Text>
            )}
            <Box sx={{ width: "100%", height: "20px" }} />
          </FormProvider>
          <Box sx={{ mt: "40px", mb: "21px" }}>
            <Divider variant="fullWidth" />
          </Box>
        </>
      ) : null}
      <Text type="error">{updateContentError}</Text>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={formMethods.handleSubmit(onSubmit)}
          variant="contained"
          sx={{
            fontSize: "18px",
            lineHeight: "24px",
            py: "16px",
            px: "48px",
            fontWeight: 500,
            right: "300px",
            backgroundColor: colors.backGroundBlue,
          }}
        >
          Submit
        </Button>

        <Button
          onClick={onCancel}
          variant="contained"
          sx={{
            fontSize: "18px",
            lineHeight: "24px",
            py: "16px",
            px: "48px",
            fontWeight: 500,
            backgroundColor: colors.backGroundBlue,
          }}
        >
          Cancel
        </Button>
      </Box>
      {isUpdateContent && <ActivityOverlay />}
    </Box>
  );
};
export default EditProductVideo;
